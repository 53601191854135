import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { dispatchWhenNotLoaded, selectStateDone } from '@icp/angular/ngrx';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs';

import { ensureSession } from './ensure-session.guard';
import { getCirkloUser } from './shared.actions';
import { selectCurrentCirkloUserResult } from './shared.feature';

export const ensureUserGuard: CanActivateFn = (route, state) => {
    const store = inject(Store);
    const router = inject(Router);
    const sessionResult = ensureSession(route, state);
    if (sessionResult !== true) {
        return sessionResult;
    }
    dispatchWhenNotLoaded(store, selectCurrentCirkloUserResult, getCirkloUser());
    return store.select(selectCurrentCirkloUserResult).pipe(
        filter(selectStateDone),
        map((user) => (user.result ? true : router.createUrlTree(['/create-user']))),
    );
};
