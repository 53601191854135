import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import { browserTracingIntegration, replayIntegration } from '@sentry/angular';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environment/environment';
import versionJson from './environment/version.json';

Sentry.init({
    dsn: 'https://5ccf47452e75009a1e9f8b967673e866@o398318.ingest.us.sentry.io/4507809429716992',
    environment: environment.env,
    release: `mbp-cirklo-app@${versionJson.version}`,
    integrations: [browserTracingIntegration(), replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ...environment.sentry,
});

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
