import { createFeature, createReducer } from '@ngrx/store';

export enum SupportedLanguage {
    NL = 'nl',
    EN = 'en',
    FR = 'fr',
}

interface CoreState {
    language: SupportedLanguage;
}

const initialCoreState: CoreState = {
    language: SupportedLanguage.NL,
};

export const coreFeature = createFeature({
    name: 'Core',
    reducer: createReducer(initialCoreState),
});
