<div class="page-content">
    <router-outlet />
</div>
<mat-toolbar *transloco="let t" class="bottom-navigation">
    @for (button of buttons(); track button.route) {
        <a
            [routerLink]="button.route"
            class="navigation-button"
            matRipple
            [matRippleCentered]="true"
            [matRippleUnbounded]="true"
            [matRippleRadius]="64"
            routerLinkActive="navigation-button-active">
            <div class="button-content">
                <mat-icon class="navigation-button-icon">{{ button.icon }}</mat-icon>
                <span class="navigation-button-label">{{ t(button.label) }}</span>
            </div>
        </a>
    }
</mat-toolbar>
