import { DOCUMENT } from '@angular/common';
import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { argbFromHex, DynamicScheme, Hct, hexFromArgb, TonalPalette } from '@material/material-color-utilities';

import { ThemeConfig } from './theme-config';

const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');

const DEFAULT_PRIMARY = '#6a51a4';
const DEFAULT_TERTIARY = '#fed718';

@Injectable({ providedIn: 'root' })
export class ThemingService {
    private document = inject(DOCUMENT);
    private isDarkTheme = signal(darkModeQuery.matches);
    private enableDarkTheme = signal(true);
    private primaryThemeColor = signal<string>(DEFAULT_PRIMARY);
    private tertiaryThemeColor = signal<string>(DEFAULT_TERTIARY);
    private theme = computed(() => {
        const sourceColorHct = Hct.fromInt(argbFromHex(this.primaryThemeColor()));
        const tertiaryHct = Hct.fromInt(argbFromHex(this.tertiaryThemeColor()));

        const primaryScheme = new DynamicScheme({
            sourceColorArgb: sourceColorHct.toInt(),
            variant: 5, // Variant.FIDELITY,
            contrastLevel: 0,
            isDark: this.enableDarkTheme() && this.isDarkTheme(),
            primaryPalette: TonalPalette.fromHueAndChroma(sourceColorHct.hue, sourceColorHct.chroma),
            secondaryPalette: TonalPalette.fromHueAndChroma(
                sourceColorHct.hue,
                Math.max(sourceColorHct.chroma - 32.0, sourceColorHct.chroma * 0.5),
            ),
            tertiaryPalette: TonalPalette.fromHct(tertiaryHct),
            neutralPalette: TonalPalette.fromHueAndChroma(sourceColorHct.hue, sourceColorHct.chroma / 8.0),
            neutralVariantPalette: TonalPalette.fromHueAndChroma(sourceColorHct.hue, sourceColorHct.chroma / 8.0 + 4.0),
        });
        const tertiaryScheme = new DynamicScheme({
            sourceColorArgb: tertiaryHct.toInt(),
            variant: 5, //Variant.FIDELITY,
            contrastLevel: 0,
            isDark: this.enableDarkTheme() && this.isDarkTheme(),
            primaryPalette: TonalPalette.fromHueAndChroma(tertiaryHct.hue, tertiaryHct.chroma),
            secondaryPalette: TonalPalette.fromHueAndChroma(
                tertiaryHct.hue,
                Math.max(tertiaryHct.chroma - 32.0, tertiaryHct.chroma * 0.5),
            ),
            tertiaryPalette: TonalPalette.fromHct(tertiaryHct),
            neutralPalette: TonalPalette.fromHueAndChroma(tertiaryHct.hue, tertiaryHct.chroma / 8.0),
            neutralVariantPalette: TonalPalette.fromHueAndChroma(tertiaryHct.hue, tertiaryHct.chroma / 8.0 + 4.0),
        });
        return { primaryScheme, tertiaryScheme };
    });

    constructor() {
        effect(() => applyTheme(this.document.documentElement, this.theme()));

        darkModeQuery.addEventListener('change', (event) => this.isDarkTheme.set(event.matches));
    }

    setupTheme(themeConfig: ThemeConfig | null, enableDarkTheme = true) {
        this.primaryThemeColor.set(themeConfig?.primary ?? DEFAULT_PRIMARY);
        this.tertiaryThemeColor.set(themeConfig?.accent ?? DEFAULT_TERTIARY);
        this.enableDarkTheme.set(enableDarkTheme);
    }
}

function applyTheme(target: HTMLElement, schemes: { primaryScheme: DynamicScheme; tertiaryScheme: DynamicScheme }) {
    const scheme = schemes.primaryScheme;
    const tertiaryScheme = schemes.tertiaryScheme;
    const properties = {
        '--sys-surface-dim': scheme.surfaceDim,
        '--sys-surface-bright': scheme.surfaceBright,
        '--sys-surface-container-lowest': scheme.surfaceContainerLowest,
        '--sys-surface-container-low': scheme.surfaceContainerLow,
        '--sys-surface-container': scheme.surfaceContainer,
        '--sys-surface-container-high': scheme.surfaceContainerHigh,
        '--sys-surface-container-highest': scheme.surfaceContainerHighest,
        '--sys-primary': scheme.primary,
        '--sys-on-primary': scheme.onPrimary,
        '--sys-primary-container': scheme.primaryContainer,
        '--sys-on-primary-container': scheme.onPrimaryContainer,
        '--sys-secondary': scheme.secondary,
        '--sys-on-secondary': scheme.onSecondary,
        '--sys-secondary-container': scheme.secondaryContainer,
        '--sys-on-secondary-container': scheme.onSecondaryContainer,
        // Instead of using the color in the primary scheme, we use a separate scheme for the tertiary color
        // because we want to retain the original color
        '--sys-tertiary': tertiaryScheme.primary,
        '--sys-on-tertiary': tertiaryScheme.onPrimary,
        '--sys-tertiary-container': tertiaryScheme.primaryContainer,
        '--sys-on-tertiary-container': tertiaryScheme.onPrimaryContainer,
        // uncomment to follow the material3 specs instead of whatever we have now
        // '--sys-tertiary': scheme.tertiary,
        // '--sys-on-tertiary': scheme.onTertiary,
        // '--sys-tertiary-container': scheme.tertiaryContainer,
        // '--sys-on-tertiary-container': scheme.onTertiaryContainer,
        '--sys-error': scheme.error,
        '--sys-on-error': scheme.onError,
        '--sys-error-container': scheme.errorContainer,
        '--sys-on-error-container': scheme.onErrorContainer,
        '--sys-background': scheme.background,
        '--sys-on-background': scheme.onBackground,
        '--sys-surface': scheme.surface,
        '--sys-on-surface': scheme.onSurface,
        '--sys-surface-variant': scheme.surfaceVariant,
        '--sys-on-surface-variant': scheme.onSurfaceVariant,
        '--sys-outline': scheme.outline,
        '--sys-outline-variant': scheme.outlineVariant,
        '--sys-shadow': scheme.shadow,
        '--sys-scrim': scheme.scrim,
        '--sys-inverse-surface': scheme.inverseSurface,
        '--sys-inverse-on-surface': scheme.inverseOnSurface,
        '--sys-inverse-primary': scheme.inversePrimary,
    };
    for (const [property, argbColor] of Object.entries(properties)) {
        target.style.setProperty(property, hexFromArgb(argbColor));
    }
}
